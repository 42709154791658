import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Head from 'next/head';
import { useState } from 'react';
import ReactPlayer from "react-player";
import { useDispatch } from 'react-redux';

import Extractor from '../features/extractor/Extractor';
import { disable, enable, setProgress } from '../features/extractor/extractorSlice';

function FilmIcon(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M488 64h-8v20c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V64H96v20c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12V64h-8C10.7 64 0 74.7 0 88v336c0 13.3 10.7 24 24 24h8v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h320v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h8c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24zM96 372c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm272 208c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm0-168c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm112 152c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40z" />
    </SvgIcon>
  )
}

const Input = styled('input')({
  display: 'none',
});

export default function Home() {
  const dispatch = useDispatch()

  const [videoUrl, setVideoUrl] = useState(null)

  function handleFileSelection(e) {
    let videoFile = e.target.files[0]
    setVideoUrl(URL.createObjectURL(videoFile))
  }

  function handlePlayerPlay() {
    dispatch(disable())
  }

  function handlePlayerPause() {
    setTimeout(() => (dispatch(enable())), 1000)
  }

  function handlePlayerProgress(progress) {
    dispatch(setProgress(progress.playedSeconds))
  }

  function handleDrop(event) {
    event.preventDefault();

    let videoFile = event.dataTransfer.files[0];

    if (videoFile) {
      setVideoUrl(URL.createObjectURL(videoFile));
    }
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  return (
    <div className="container" onDrop={handleDrop} onDragOver={handleDragOver} style={{ minHeight: '100vh' }}>
      <Head>
        <title>Video Frame Extractor</title>

        <meta name="description" content="Online video frame extraction tool that doesn't require file upload to any server." />

        <link rel="shortcut icon" href="icon.svg" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="manifest" href="manifest.json" />
      </Head>

      <CssBaseline />

      <Container component="main" maxWidth="md">
        <Box
          sx={{
            paddingTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <FilmIcon fontSize="large" />
            <Typography component="h1" variant="h4" align="center">
              Video Frame Extractor
            </Typography>
          </Stack>

          <label htmlFor="contained-button-file">
            <Input accept="video/*" id="contained-button-file" type="file" onChange={handleFileSelection} />
            <Button variant="contained" component="span" sx={{marginTop: 3}}>
              Select video file
            </Button>
          </label>

          <Typography align="center" variant="caption" sx={{marginTop: 3}}>
              … or drag and drop a video file anywhere on this page.
          </Typography>

          {!videoUrl &&
            <Typography align="center" variant="caption" sx={{marginTop: 3}}>
              The video never leaves your computer.<br/>
              Frame extraction happens inside your browser.<br/>
              Frames are extracted in video's resolution.
            </Typography>
          }

          {videoUrl &&
            <Stack align="center" spacing={3} sx={{marginTop: 3}}>
              <ReactPlayer
                url={videoUrl}
                width="100%"
                height="100%"
                controls={true}
                onPlay={handlePlayerPlay}
                onPause={handlePlayerPause}
                onProgress={handlePlayerProgress}
              />
              <Extractor videoUrl={videoUrl} />
            </Stack>
          }

          <Typography color="gray" align="center" variant="caption" sx={{marginTop: 3, marginBottom: 3}}>
            Created by <a href="https://szuliq.dev" target="_blank">szuliq</a>.<br/>
            Do you have a <a href="https://frame-extractor.canny.io/" target="_blank">feature request</a>?
          </Typography>
        </Box>
      </Container>
    </div>
  )
}
